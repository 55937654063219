import styled, { css } from 'styled-components'

const alertColorByStatus = {
  error: '#C40808',
  success: '#01ADA6',
  warning: '#E0860A',
  info: '#6224C6',
  loading: '#3F5DD8',
}

const alertBgColorByStatus = {
  error: '#FFE2E2',
  success: '#EFFFFE',
  warning: '#FFFAEB',
  info: '#F6F0FF',
  loading: '#EFEFFF',
}

const setAlertColorVars = (status) => {
  return css`
    --alert-color: ${alertColorByStatus[status]};
    --alert-bg-color: ${alertBgColorByStatus[status]};
  `
}

export const AlertContainer = styled.div`
  ${({ $status }) => setAlertColorVars($status)}

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--alert-bg-color);
  color: var(--alert-color);
`

export const AlertIcon = styled.div`
  width: fit-content;
  height: fit-content;
  flex-shrink: 0;
`

export const AlertContent = styled.div``

export const AlertTitle = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`
