import styled from 'styled-components'

export const WorkspaceBrandActions = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;

  & > button {
    margin: 0;
  }
`

export const WorkspaceBrandField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
