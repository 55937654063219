import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

const Input = styled.input`
  width: 100%;
  height: 38px;
  padding: 10px;
  font-weight: 300;
  color: hsl(0, 0%, 20%);
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;

  &:hover {
    border: 1px solid ${COLORS.gray};
  }

  &:focus {
    border: 1px solid ${COLORS.primary};
  }

  &.warning {
    border: 1px solid ${COLORS.warning};
  }

  &.error {
    border: 1px solid ${COLORS.danger};
  }
`

export const InputError = styled.p`
  position: absolute;
  color: ${COLORS.danger};
  font-size: 12px;
  font-weight: bold;
  transition: 200ms;
  opacity: ${({ active }) => (active ? '1' : '0')};
`

export const Label = styled.label`
  ${({ small }) =>
    small
      ? `font-size: 14px;
        font-weight: normal;`
      : `font-size: 16px;
        font-weight: 600;`}

  display: block;
  color: ${COLORS.gray_dark2};
  margin-bottom: 7px;

  ${({ required }) =>
    required &&
    `&::after {
      content: ' *';
      color: ${COLORS.primary};
    }`}
`

export default Input
