import { gql } from 'apollo-boost'

export const GET_BRAND_OPTIONS = gql`
  query GET_BRAND_OPTIONS {
    listBrandSOAOnCategoryPaginated {
      data {
        id
        brand {
          id
          name
        }
        category {
          name
        }
        language
      }
      totalCount
      cursor
    }
  }
`

export const SET_BRAND_OPTION = gql`
  mutation SET_BRAND_OPTION($input: CreateWorkspaceBrandCategoryInput!) {
    createWorkspaceBrandSOAOnCategory(input: $input) {
      id
      brand {
        id
        name
      }
    }
  }
`
