import React from 'react'

/**
 * @param {Object} props
 * @param {number} [props.width=24] - The width of the icon
 * @param {number} [props.height=24] - The height of the icon
 */
export function CheckIcon({ width = 24, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3536 6.89645C14.5488 7.09171 14.5488 7.40829 14.3536 7.60355L8.85355 13.1036C8.75979 13.1973 8.63261 13.25 8.5 13.25C8.36739 13.25 8.24021 13.1973 8.14645 13.1036L5.64645 10.6036C5.45118 10.4083 5.45118 10.0917 5.64645 9.89645C5.84171 9.70118 6.15829 9.70118 6.35355 9.89645L8.5 12.0429L13.6464 6.89645C13.8417 6.70118 14.1583 6.70118 14.3536 6.89645Z"
        fill="currentColor"
      />
    </svg>
  )
}
