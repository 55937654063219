import { useCallback, useMemo, useState } from 'react'
import { matchPath } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import {
  GET_TERRITORY_INFO,
  SAVE_TERRITORY_TO_WORKSPACE,
  VALIDATE_TOPICLINK_FOR_SAVE_WORKSPACE_CATEGORY_TERRITORIES,
} from './query'

export const useSaveTerritoryToWorkspace = () => {
  const [isTerritoryLinkInvalid, setIsTerritoryLinkInvalid] = useState(false)
  const [territoryId, setTerritoryId] = useState(null)

  const [getTerritory, { data: territoryData }] =
    useLazyQuery(GET_TERRITORY_INFO)
  const [saveTerritoryToWorkspaceMutation, { loading }] = useMutation(
    SAVE_TERRITORY_TO_WORKSPACE,
    {
      onCompleted: () => {
        if (territoryId) {
          getTerritory({
            variables: {
              territoryId,
            },
          })
        }
      },
      onError: (error) => {
        console.error(
          'useSaveTerritoryToWorkspace @ >>> SAVE_TERRITORY_TO_WORKSPACE: ',
          error
        )
      },
    }
  )
  const [
    validateTopicLink,
    {
      data: validateTopicLinkData,
      loading: validateTopicLinkLoading,
      error: validateTopicLinkError,
    },
  ] = useLazyQuery(VALIDATE_TOPICLINK_FOR_SAVE_WORKSPACE_CATEGORY_TERRITORIES)

  const saveTerritoryToWorkspace = useCallback(
    async (territoryURL, workspaceId) => {
      try {
        const { data } = await saveTerritoryToWorkspaceMutation({
          variables: {
            topicLink: territoryURL,
            workspaceId,
          },
        })

        return data
      } catch (error) {
        console.error(error)
      }
    },
    []
  )

  const checkTerritoryUrlIsValid = useCallback((territoryURL) => {
    try {
      setIsTerritoryLinkInvalid(false)
      setTerritoryId(null)

      const pathFromURL = new URL(territoryURL).pathname

      const allowedTerritoryPaths = [
        '/subtopic/:subtopicId/:tab?',
        '/topic/:topicId/:tab?',
        '/workspace/:workspaceId/saved-search/:savedSearchId/:tab?',
      ]

      const match = matchPath(pathFromURL, {
        path: allowedTerritoryPaths,
        exact: true,
      })

      setIsTerritoryLinkInvalid(!match)

      if (match) {
        const entityTypeByPathInit = {
          subtopic: 'subtopic',
          topic: 'topic',
          workspace: 'saved_search',
        }
        const entityType = entityTypeByPathInit[pathFromURL.split('/')[1]]
        const entityId =
          match?.params?.topicId ||
          match?.params?.subtopicId ||
          match?.params?.savedSearchId
        const territoryIdFromUrl = `${entityType}.${entityId}`

        setTerritoryId(territoryIdFromUrl)
      }

      return match
    } catch {
      setIsTerritoryLinkInvalid(true)
      return false
    }
  }, [])

  const validateTerritory = useCallback(async (territoryURL, workspaceId) => {
    try {
      validateTopicLink({
        variables: {
          topicLink: territoryURL,
          workspaceId,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  const resetTerritoryStatus = useCallback(() => {
    setIsTerritoryLinkInvalid(false)
  }, [])

  const isFetchingTerritory = !!validateTopicLinkLoading
  const isTerritoryVideoCountInsufficient =
    validateTopicLinkError?.message?.includes('Topic has less than 100 videos')
  const isTerritoryNotBelongingToWorkspace =
    validateTopicLinkError?.message?.includes(
      'workspaceId does not match with the workspaceId from the topicLink'
    )
  const isTerritoryValid =
    !!validateTopicLinkData
      ?.validateTopicLinkForSaveWorkspaceCategoryTerritories?.success
  const selectedTerritory = territoryData?.getTerritory
  const isSavingTerritory = loading

  const memoizedValues = useMemo(
    () => ({
      isFetchingTerritory,
      isTerritoryValid,
      isTerritoryVideoCountInsufficient,
      isTerritoryNotBelongingToWorkspace,
      isTerritoryLinkInvalid,
      selectedTerritory,
      isSavingTerritory,
      setIsTerritoryLinkInvalid,
      saveTerritoryToWorkspace,
      checkTerritoryUrlIsValid,
      validateTerritory,
      resetTerritoryStatus,
    }),
    [
      isFetchingTerritory,
      isTerritoryValid,
      isTerritoryVideoCountInsufficient,
      isTerritoryNotBelongingToWorkspace,
      isTerritoryLinkInvalid,
      selectedTerritory,
      isSavingTerritory,
      setIsTerritoryLinkInvalid,
      saveTerritoryToWorkspace,
      checkTerritoryUrlIsValid,
      validateTerritory,
      resetTerritoryStatus,
    ]
  )

  return memoizedValues
}
