import React, { useMemo } from 'react'
import Modal from '../Modal'
import ModalTabs from '../ModalTabs'
import EditWorkspaceTab from './tabs/EditWorkspaceTab'
import AssociatedBrandsTab from '../CompanyModal/tabs/AssociatedBrandsTab'
import WorkspaceBrand from './tabs/WorkspaceBrand'

const WorkspaceModal = ({ isOpen, onRequestClose, brand, onCreateOrEdit }) => {
  const tabs = useMemo(() => {
    if (brand) {
      return {
        workspace: (
          <EditWorkspaceTab
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            brand={brand}
            onCreateOrEdit={onCreateOrEdit}
          />
        ),
        brands: (
          <AssociatedBrandsTab
            onRequestClose={onRequestClose}
            company={brand?.company}
          />
        ),
        Categoria: (
          <WorkspaceBrand onRequestClose={onRequestClose} company={brand} />
        ),
      }
    }
    return {
      workspace: (
        <EditWorkspaceTab
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          brand={brand}
          onCreateOrEdit={onCreateOrEdit}
        />
      ),
    }
  }, [isOpen, onRequestClose, brand, onCreateOrEdit])

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalTabs tabs={tabs} />
    </Modal>
  )
}

export default WorkspaceModal
