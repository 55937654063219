import { useRef } from 'react'

/**
 * @param {Function} action - The function to be debounced
 * @param {number} [wait=1000] - The time to wait before executing the debounced function
 */
export const useDebounce = (action, wait = 1000) => {
  const timeoutRef = useRef(undefined)

  const debouncedAction = (...args) => {
    window.clearTimeout(timeoutRef.current)
    timeoutRef.current = window.setTimeout(() => {
      if (args) {
        action(...args)
      } else {
        action()
      }
    }, wait)
  }

  return {
    debouncedAction,
    cancelDebouncedAction: () => {
      window.clearTimeout(timeoutRef.current)
    },
  }
}
