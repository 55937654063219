import { gql } from 'apollo-boost'

export const SAVE_TERRITORY_TO_WORKSPACE = gql`
  mutation SAVE_TERRITORY_TO_WORKSPACE(
    $topicLink: String!
    $workspaceId: String!
  ) {
    saveWorkspaceCategoryTerritories(
      topicLink: $topicLink
      workspaceId: $workspaceId
    ) {
      entityId
      statusMessage {
        message
        success
      }
    }
  }
`

export const VALIDATE_TOPICLINK_FOR_SAVE_WORKSPACE_CATEGORY_TERRITORIES = gql`
  query VALIDATE_TOPICLINK_FOR_SAVE_WORKSPACE_CATEGORY_TERRITORIES(
    $topicLink: String!
    $workspaceId: String!
  ) {
    validateTopicLinkForSaveWorkspaceCategoryTerritories(
      topicLink: $topicLink
      workspaceId: $workspaceId
    ) {
      message
      success
    }
  }
`

export const GET_TERRITORY_INFO = gql`
  query GET_TERRITORY_INFO($territoryId: ID!) {
    getTerritory(territoryId: $territoryId) {
      territoryId
      name
    }
  }
`
