import React from 'react'

import { AlertIcon, CheckIcon, InfoIcon, SpinnerIcon } from '../Icons'

import * as S from './styles'

const iconByStatus = {
  loading: <SpinnerIcon width={16} height={16} />,
  error: <AlertIcon width={20} height={20} />,
  success: <CheckIcon width={20} height={20} />,
  warning: <AlertIcon width={20} height={20} />,
  info: <InfoIcon width={20} height={20} />,
}

/**
 * @param {Object} props
 * @param {string} props.title - The title of the alert
 * @param {'error' | 'success' | 'warning' | 'info' | 'loading'} props.status - The status of the alert
 * @param {React.ReactNode} [props.icon] - The icon of the alert
 */
export function Alert({ title, status, icon }) {
  return (
    <S.AlertContainer $status={status}>
      {!!icon && <S.AlertIcon>{icon}</S.AlertIcon>}
      {!icon && <S.AlertIcon>{iconByStatus[status]}</S.AlertIcon>}
      <S.AlertContent>
        <S.AlertTitle>{title}</S.AlertTitle>
      </S.AlertContent>
    </S.AlertContainer>
  )
}
